$xs: 375px;
$s: 576px;
$m: 768px;
$l: 992px;
$xl: 1200px;

$otip-primary-color: #D9A867;
$otip-primary-light-color: #E1CAAC;
$otip-secondary-color: #366B92;

$facet-primary-color: #1B6F65;
$facet-primary-light-color: #40A295;

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system,BlinkMacSystemFont,Roboto,Segoe UI,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}