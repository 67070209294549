@use "../../theme.scss" as theme;

#home-page {
  font-family: 'Open Sans',system-ui,sans-serif;
  background-color: #FFFFFF;
  margin: 0;
}

#background {
  box-sizing: border-box;
  min-width: 0;
  z-index: 2;
  position: relative;
  height: auto;
  background-size: cover;
  background-position: 80% 100%;
  background-image: url(../../../public/static/home-hero.jpg);
  background-repeat: no-repeat;
  &::before {
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  #main-content-wrapper {
    box-sizing: border-box;
    min-width: 0;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 0;
    padding-bottom: 0px !important;
    width: 100%;
    height: 95vh;
    justify-content: center;
    flex-direction: column;
    position: relative;

    #main-content {
      box-sizing: border-box;
      min-width: 0;
      width: 100%;
      text-align: center;
      font-size: 1.8vh;
      padding-bottom: 0px !important;
    }

  }
}

@media (max-width: theme.$xs) {
  #background {
    height: 100vh;
    background-position: 80% 100%;
    &::before {
      opacity: 0.67;
      background-image: linear-gradient(91deg,white 57%,rgba(255,255,255,0.8) 84%,rgba(255,255,255,0.8) 110%);
    }

    #main-content-wrapper {
      padding: 10px;
      margin-top: 70px;
      height: 100vh;
      display: block;

      #main-content {
        width: 100%;
        text-align: center;
        font-size: 1.8vh;

        h1 {
          margin-top: 5px;
          margin-bottom: 5px;
        }
      }
    }
  }
}

@media (min-width: theme.$xs) {
  #background {
    height: 100vh;
    background-position: 80% 100%;
    &::before {
      opacity: 0.67;
      background-image: linear-gradient(91deg,white 57%,rgba(255,255,255,0.8) 84%,rgba(255,255,255,0.8) 110%);
    }

    #main-content-wrapper {
      padding: 10px;
      margin-top: 70px;
      height: calc(100vh - 70px - 20px);
      display: block;

      #main-content {
        width: 100%;
        text-align: center;
        font-size: 1.8vh;

        h1 {
          margin-top: 5px;
          margin-bottom: 5px;
        }
      }
    }
  }
}

@media (min-width: theme.$s) {
  #background {
    height: 100vh;
    background-position: 80% 100%;
    &::before {
      opacity: 0.67;
      background-image: linear-gradient(91deg,white 57%,rgba(255,255,255,0.8) 84%,rgba(255,255,255,0.8) 110%);
    }

    #main-content-wrapper {
      padding: 10px 25px;
      margin-top: 70px;
      height: calc(100vh - 70px - 20px);
      display: flex;

      #main-content {
        width: 100%;
        text-align: center;
        font-size: 1.8vh;

        h1 {
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }
    }
  }
}

@media (min-width: theme.$m) {
  #background {
    height: 100vh;
    background-position: 80% 100%;
    &::before {
      opacity: 0.67;
      background-image: linear-gradient(to right,white 57%,rgba(255,255,255,0.15) 84%,rgba(28,67,57,0) 111%);
    }

    #main-content-wrapper {
      padding: 40px;
      margin-top: 100px;
      height: calc(100vh - 80px);
      display: flex;

      #main-content {
        width: 60vw;
        text-align: left;
        font-size: 2vh;

        h1 {
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }
    }
  }
}

@media (min-width: theme.$l) {
  #background {
    height: 100vh;
    background-position: 80% 100%;
    &::before {
      opacity: 0.67;
      background-image: linear-gradient(to right,white 57%,rgba(255,255,255,0.15) 84%,rgba(28,67,57,0) 111%);
    }

    #main-content-wrapper {
      padding: 25px 40px;
      margin-top: 100px;
      height: calc(100vh - 100px - 25px - 25px);
      display: flex;

      #main-content {
        width: 60vw;
        text-align: left;
        font-size: 2vh;

        h1 {
          margin-top: 15px;
          margin-bottom: 15px;
        }
      }
    }
  }
}

@media (min-width: theme.$xl) {
  #background {
    height: 100vh;
    background-position: top center;
    &::before {
      opacity: 0.9;
    }

    #main-content-wrapper {
      padding-left: 150px;
      padding-right: 150px;
      padding-top: 45px;
      padding-bottom: 25px;
      margin-top: 0;
      height: 100vh;
      display: flex;

      #main-content {
        width: 52vw;
        font-size: 2vh;

        h1 {
          margin-top: 15px;
          margin-bottom: 15px;
        }
      }
    }
  }
}