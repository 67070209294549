@use "../../theme.scss" as theme;

#process {
  box-sizing: border-box;
  min-width: 0;
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 40px;

  h2 {
    margin: 0;
    font-family: 'Montserrat',system-ui,sans-serif;
    font-weight: 500;
    line-height: 1.33;
    font-weight: 700;
    padding-bottom: 0;

    &.facet {
      color: theme.$facet-primary-color;
    }

    &.otip {
      color: theme.$otip-secondary-color;
    }
  }

  #list-wrapper {
    box-sizing: border-box;
    min-width: 0;
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;

    .list {
      box-sizing: border-box;
      min-width: 0;
      display: flex;
      align-items: flex-start;
      justify-content: center;
    }

    .img-wrapper {
      box-sizing: border-box;
      min-width: 0;
      height: 100%;

      img {
        width: 100%;
      }
    }

    .description-wrapper {
      box-sizing: border-box;
      min-width: 0;
      display: flex;
      align-content: flex-start;
      flex-direction: column;

      h5 {
        margin: 0;
        font-family: 'Montserrat',system-ui,sans-serif;
        line-height: 1.33;
        font-weight: 300;

        &.facet {
          color: theme.$facet-primary-color;
        }

        &.otip {
          color: theme.$otip-secondary-color;
        }
      }

      p {
        margin: 0;
        font-family: 'Open Sans',system-ui,sans-serif;
        line-height: 1.38;
        padding-top: 10px;
      }
    }
  }
}

@media (max-width: theme.$xs) {
  #process {
    padding: 40px 0;

    h2 {
      padding-bottom: 0;
      font-size: 25px;
    }

    .list {
      width: 100%;
      padding-left: 25px;
      padding-right: 25px;
      justify-content: center;
      padding-top: 20px;
      padding-bottom: 20px;
    }

    .img-wrapper {
      box-sizing: border-box;
      min-width: 0;
      height: 100%;
    }

    .description-wrapper {
      width: 100%;

      h5 {
        padding-top: 0px;
        font-size: 18px;
      }

      p {
        font-weight: 700;
        font-size: 16px;
      }
    }
  }
}

@media (min-width: theme.$xs) {
  #process {
    padding: 40px 0;

    h2 {
      padding-bottom: 0;
      font-size: 25px;
    }

    .list {
      width: 100%;
      padding-left: 25px;
      padding-right: 25px;
      justify-content: center;
      padding-top: 20px;
      padding-bottom: 20px;
    }

    .img-wrapper {
      margin-top: 5px;
      padding-right: 15px;
      max-width: 100px;
    }

    .description-wrapper {
      width: 100%;

      h5 {
        padding-top: 0px;
        font-size: 18px;
      }

      p {
        font-weight: 700;
        font-size: 16px;
      }
    }
  }
}

@media (min-width: theme.$s) {
  #process {
    padding: 40px 0;

    h2 {
      padding-bottom: 0;
      font-size: 25px;
    }

    .list {
      width: 100%;
      padding-left: 25px;
      padding-right: 25px;
      justify-content: center;
      padding-top: 20px;
      padding-bottom: 20px;
    }

    .img-wrapper {
      margin-top: 5px;
      padding-right: 15px;
      max-width: 100px;
    }

    .description-wrapper {
      width: 100%;

      h5 {
        padding-top: 0px;
        font-size: 18px;
      }

      p {
        font-weight: 700;
        font-size: 16px;
      }
    }
  }
}

@media (min-width: theme.$m) {
  #process {
    padding: 80px 0;

    h2 {
      padding-bottom: 0;
      font-size: 25px;
    }

    .list {
      width: 100%;
      padding-left: 40px;
      padding-right: 40px;
      justify-content: center;
      padding-top: 25px;
      padding-bottom: 25px;
    }

    .img-wrapper {
      margin-top: 0;
      padding-right: 30px;
      max-width: 145px;
    }

    .description-wrapper {
      width: 95%;

      h5 {
        padding-top: 0px;
        font-size: 22px;
      }

      p {
        font-weight: 700;
        font-size: 20px;
      }
    }
  }
}

@media (min-width: theme.$l) {
  #process {
    padding: 80px 0;

    h2 {
      padding-bottom: 0;
      font-size: 25px;
    }

    .list {
      width: 100%;
      padding-left: 40px;
      padding-right: 40px;
      justify-content: center;
      padding-top: 25px;
      padding-bottom: 25px;
    }

    .img-wrapper {
      margin-top: 0;
      padding-right: 30px;
      max-width: 145px;
    }

    .description-wrapper {
      width: 95%;

      h5 {
        padding-top: 0px;
        font-size: 22px;
      }

      p {
        font-weight: 700;
        font-size: 20px;
      }
    }
  }
}

@media (min-width: theme.$xl) {
  #process {
    padding: 150px 0;

    h2 {
      padding-bottom: 30px;
      font-size: 39px;
    }

    .list {
      width: 80%;
      padding-left: 180px;
      padding-right: 180px;
      justify-content: center;
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .img-wrapper {
      max-width: 162px;
    }

    .description-wrapper {
      width: 60%;

      h5 {
        padding-top: 10px;
        font-size: 22px;
      }

      p {
        font-weight: 600;
      }
    }
  }
}