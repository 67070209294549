@use "../../theme.scss" as theme;

#faqs {
  box-sizing: border-box;
  min-width: 0;
  display: flex;
  width: 100%;
  background-color: #FBFBFB;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;

  h2 {
    margin: 0;
    font-family: 'Montserrat',system-ui,sans-serif;
    font-weight: 500;
    line-height: 1.33;
    max-width: 80%;
    font-weight: 700;
    text-align: center;
    padding-bottom: 30px;

    &.facet {
      color: theme.$facet-primary-color;
    }

    &.otip {
      color: theme.$otip-secondary-color;
    }
  }

  #tab-wrapper {
    box-sizing: border-box;
    min-width: 0;
    width: 100%;

    [role=tabpanel] {
      background-color: white;
    }

    ul {
      list-style-type: square;
      width: 85%;
      margin: auto;
      padding-top: 40px;
    }

    li {
      margin: 5px;
      padding: 10px;
      border-radius: 4px;
    }
  }
}

@media (max-width: theme.$xs) {
  #faqs {
    padding: 40px 0 0 0;
  }

  h2 {
    padding-bottom: 30px;
    font-size: 25px;
  }

  #tab-wrapper {
    max-width: 100%;

    #tab {
      font-size: 18px;
    }

    .tab-content-wrapper {
      padding: 5px;
    }
  }
}

@media (min-width: theme.$xs) {
  #faqs {
    padding: 40px 0 0 0;
  }

  h2 {
    padding-bottom: 30px;
    font-size: 25px;
  }

  #tab-wrapper {
    max-width: 100%;

    #tab {
      font-size: 18px;
    }

    .tab-content-wrapper {
      padding: 5px;
    }
  }
}

@media (min-width: theme.$s) {
  #faqs {
    padding: 40px 0 0 0;
  }

  h2 {
    padding-bottom: 30px;
    font-size: 25px;
  }

  #tab-wrapper {
    max-width: 100%;

    #tab {
      font-size: 18px;
    }

    .tab-content-wrapper {
      padding: 5px;
    }
  }
}

@media (min-width: theme.$m) {
  #faqs {
    padding: 80px 0 0 0;
  }

  h2 {
    padding-bottom: 40px;
    font-size: 39px;
  }

  #tab-wrapper {
    max-width: 100%;

    #tab {
      font-size: 18px;
    }

    .tab-content-wrapper {
      padding: 20px;
    }
  }
}

@media (min-width: theme.$l) {
  #faqs {
    padding: 80px 0 0 0;
  }

  h2 {
    padding-bottom: 40px;
    font-size: 39px;
  }

  #tab-wrapper {
    max-width: 100%;

    #tab {
      font-size: 18px;
    }

    .tab-content-wrapper {
      padding: 20px;
    }
  }
}

@media (min-width: theme.$xl) {
  #faqs {
    padding: 80px;
  }

  h2 {
    padding-bottom: 40px;
    font-size: 39px;
  }

  #tab-wrapper {
    max-width: 80%;

    #tab {
      font-size: 22px;
    }

    .tab-content-wrapper {
      padding: 20px;
    }
  }
}