@use "../../theme.scss" as theme;

#home-page {
  h1 {
    margin: 0;
    font-family: 'Montserrat',system-ui,sans-serif;
    font-weight: 500;
    line-height: 1.33;
    font-size: 25px;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 700;
    font-size: 4.5vh !important;
  }

  h1.facet {
    color: theme.$facet-primary-color;
  }

  h1.otip {
    color: theme.$otip-secondary-color;
  }

  #content-wrapper {
    box-sizing: border-box;
    min-width: 0;
    padding-bottom: 0px !important;
  }

  #button-group {
    box-sizing: border-box;
    min-width: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 5px;
    flex-direction: column;
    justify-content: center;

    &.facet {
      .button {
        color: #262626;
        border-color: theme.$facet-primary-color;

        &:hover {
          background-color: #CBF1E6;
        }

        &:nth-of-type(1) {
          color: #FFFFFF;
          background-color: theme.$facet-primary-color;

          &:hover {
            background-color: #26A295;
            border-color: #26A295;
          }
        }
      }
    }

    &.otip {
      .button {
        color: #262626;
        border-color: theme.$otip-primary-color;

        &:hover {
          background-color: theme.$otip-primary-light-color;
        }

        &:nth-of-type(1) {
          background-color: theme.$otip-primary-color;

          &:hover {
            background-color: theme.$otip-primary-light-color;
            border-color: theme.$otip-primary-light-color;
          }
        }
      }
    }

    .button {
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 10px;
      padding-right: 10px;
      background-color: #FFFFFF;
      font-size: 16px;
      cursor: pointer;
      font-family: 'Open Sans',system-ui,sans-serif;
      border-width: 2px;
      border-radius: 3px;
      text-align: center;
      border-style: solid;
      font-weight: 600;
      width: 100%;
      max-width: 300px;
      text-decoration: none;

      &:hover {
        color: #000000;
        border-width: 2px;
        -webkit-text-decoration: none;
        text-decoration: none;
      }

      &:nth-of-type(1) {
        background-color: theme.$otip-primary-color;
        color: #000;
        border-radius: 4px;

        &:hover {
          color: #FFFFFF;
          background-color: theme.$otip-primary-light-color;
          text-decoration: none;
          border-color: theme.$otip-primary-light-color;
        }
      }
    }
  }
}

@media (max-width: theme.$xs) {
  #main-content {
    h1 {
      margin-top: 5px;
      margin-bottom: 5px;
    }

    #content {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  #button-group {
    padding-top: 5px;
    align-items: center;

    .button:nth-of-type(1) {
      margin-bottom: 5px;
    }
  }
}

@media (min-width: theme.$xs) {
  #main-content {
    h1 {
      margin-top: 5px;
      margin-bottom: 5px;
    }

    #content {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  #button-group {
    padding-top: 5px;
    align-items: center;

    .button:nth-of-type(1) {
      margin-bottom: 5px;
    }
  }
}

@media (min-width: theme.$s) {
  #main-content {
    h1 {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    #content {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  #button-group {
    padding-top: 5px;
    align-items: flex-start;

    .button:nth-of-type(1) {
      margin-bottom: 5px;
    }
  }
}

@media (min-width: theme.$m) {
  #main-content {
    h1 {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    #content {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  #button-group {
    padding-top: 15px;
    align-items: flex-start;

    .button:nth-of-type(1) {
      margin-bottom: 15px;
    }
  }
}

@media (min-width: theme.$l) {
  #main-content {
    h1 {
      margin-top: 15px;
      margin-bottom: 15px;
    }

    #content {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }

  #button-group {
    padding-top: 15px;
    align-items: flex-start;

    .button:nth-of-type(1) {
      margin-bottom: 15px;
    }
  }
}

@media (min-width: theme.$xl) {
  #main-content {
    h1 {
      margin-top: 15px;
      margin-bottom: 15px;
    }

    #content {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }

  #button-group {
    padding-top: 0;
    align-items: flex-start;

    .button:nth-of-type(1) {
      margin-bottom: 15px;
    }
  }
}
