@use "../../theme.scss" as theme;

#privacy-page {
  #content-wrapper {
    box-sizing: border-box;
    min-width: 0;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;

    #content {
      box-sizing: border-box;
      min-width: 0;
      display: flex;
      text-align: center;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      padding-top: 60px;

      h1 {
        margin: 0;
        font-family: 'Montserrat',system-ui,sans-serif;
        line-height: 1.33;
        width: fit-content;
        font-weight: 700;

        &.facet {
          color: theme.$facet-primary-color;
        }

        &.otip {
          color: theme.$otip-primary-color;
        }
      }

      p {
        margin: 0px;
        font-size: 16px;
        font-family: "Open Sans", system-ui, sans-serif;
        line-height: 1.38;
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        unicode-bidi: isolate;
      }

      h3 {
        margin: 0px;
        font-family: Montserrat, system-ui, sans-serif;
        line-height: 1.33;
        color: rgb(38, 38, 38);
        font-weight: 400;
      }

      h4 {
        margin: 0px;
        line-height: 1.33;
        font-family: "Open Sans", system-ui, sans-serif;
        font-size: 20px;
        color: rgb(38, 38, 38);
        font-weight: 600;
      }

      #paragraph-wrapper {
        box-sizing: border-box;
        min-width: 0;
        width: 100%;
        text-align: left;
        white-space: pre-line;
      }
    }

    a {
      text-decoration: none;
    }
  }
}

@media (max-width: theme.$xs) {
  #privacy-page {
    #content-wrapper {
      padding: 20px 30px;

      #content {
        padding-top: 30px;

        h1 {
          padding: 0 0;
          font-size: 25px;
        }

        h3 {
          font-size: 22px;
        }

        #paragraph-wrapper {
          padding-top: 30px;
        }
      }
    }
  }
}

@media (min-width: theme.$xs) {
  #privacy-page {
    #content-wrapper {
      padding: 20px 30px;

      #content {
        padding-top: 30px;

        h1 {
          padding: 0 0;
          font-size: 25px;
        }

        h3 {
          font-size: 22px;
        }

        #paragraph-wrapper {
          padding-top: 30px;
        }
      }
    }
  }
}

@media (min-width: theme.$s) {
  #privacy-page {
    #content-wrapper {
      padding: 20px 30px;

      #content {
        padding-top: 60px;

        h1 {
          padding: 0 0;
          font-size: 25px;
        }

        h3 {
          font-size: 22px;
        }

        #paragraph-wrapper {
          padding-top: 30px;
        }
      }
    }
  }
}

@media (min-width: theme.$m) {
  #privacy-page {
    #content-wrapper {
      padding: 40px 100px;

      #content {
        padding-top: 30px;

        h1 {
          padding: 0 0;
          font-size: 39px;
        }

        h3 {
          font-size: 34px;
        }

        #paragraph-wrapper {
          padding-top: 40px;
        }
      }
    }
  }
}

@media (min-width: theme.$l) {
  #privacy-page {
    #content-wrapper {
      padding: 40px 100px;

      #content {
        padding-top: 30px;

        h1 {
          padding: 0 15px;
          font-size: 39px;
        }

        h3 {
          font-size: 34px;
        }

        #paragraph-wrapper {
          padding-top: 40px;
        }
      }
    }
  }
}

@media (min-width: theme.$xl) {
  #privacy-page {
    #content-wrapper {
      padding: 100px;

      #content {
        width: 80%;

        h1 {
          padding: 0 100px;
          font-size: 39px;
        }

        h3 {
          font-size: 34px;
        }

        #paragraph-wrapper {
          padding-top: 40px;
        }
      }
    }
  }
}