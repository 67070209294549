@use "../../theme.scss" as theme;

#header {
  box-sizing: border-box;
  min-width: 0px;
  top: 0px;
  left: 0px;
  position: fixed;
  padding: 10px 15px;
  width: 100%;
  z-index: 3;
  background-color: rgb(255, 255, 255);

  #header-content {
    box-sizing: border-box;
    min-width: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    #menu {
      box-sizing: border-box;
      min-width: 0;
      nav {
        display: flex;

        #home-btn-wrapper {
          margin: 10px 30px 0 0;

          a {
            text-decoration: none;
            background-color: transparent;
            color: rgb(38, 38, 38);
            min-height: 26px;
            font-family: "Open Sans", system-ui, sans-serif;
            letter-spacing: 0.034em;

            &:hover {
              letter-spacing: 0;
              text-decoration: none;
              font-weight: 700;

              &.facet {
                border-bottom: 2px solid theme.$facet-primary-color;
              }

              &.otip {
                border-bottom: 2px solid theme.$otip-secondary-color;
              }
            }
          }
        }
      }

      #language-toggle {
        box-sizing: border-box;
        min-width: 0px;
        padding: 0px;
        background-color: rgb(255, 255, 255);
        font-size: 22px;
        cursor: pointer;
        font-family: "Open Sans", system-ui, sans-serif;
        border-width: 2px;
        border-radius: 4px;
        text-align: center;
        border-style: solid;
        font-weight: 600;
        letter-spacing: 1px;
        width: 46px;
        height: 46px;

        &.facet {
          border-color: theme.$facet-primary-color;
          color: theme.$facet-primary-color;

          &:hover {
            background-color: theme.$facet-primary-light-color;
            border-color: theme.$facet-primary-light-color;
            color: white;
          }
        }

        &.otip {
          border-color: theme.$otip-primary-color;
          color: theme.$otip-secondary-color;

          &:hover {
            border-color: theme.$otip-primary-light-color;
            background-color: theme.$otip-primary-light-color;
            color: white;
          }
        }
      }
    }
  }

  #logo-wrapper {
    img {
      height: auto;
      vertical-align: middle;
      border-style: none;
    }

    &.facet {
      img {
        max-width: 100%;
      }
    }

    &.otip {
      img {
        max-width: 150%;
      }
    }
  }
}

@media (max-width: theme.$xs) {
  #header {
    padding: 10px 15px;
    background-color: rgb(255, 255, 255);
    height: 70px;

    &.scrolled {
      background-image: none;
    }

    #logo-wrapper {
      max-width: 90px;
    }
  }
}

@media (min-width: theme.$xs) {
  #header {
    padding: 10px 15px;
    background-color: rgb(255, 255, 255);
    height: 70px;

    &.scrolled {
      background-image: none;
    }

    #logo-wrapper {
      max-width: 90px;
    }
  }
}

@media (min-width: theme.$s) {
  #header {
    padding: 10px 15px;
    background-color: rgb(255, 255, 255);
    height: 70px;

    &.scrolled {
      background-image: none;
    }

    #logo-wrapper {
      max-width: 90px;
    }
  }
}

@media (min-width: theme.$m) {
  #header {
    padding: 15px 40px;
    background-color: rgb(255, 255, 255);
    height: 100px;

    &.scrolled {
      background-image: none;
    }

    #logo-wrapper {
      max-width: 132px;
    }
  }
}

@media (min-width: theme.$l) {
  #header {
    padding: 15px 40px;
    background-color: rgb(255, 255, 255);
    height: 100px;

    &.scrolled {
      background-image: none;
    }

    #logo-wrapper {
      max-width: 132px;
    }
  }
}

@media (min-width: theme.$xl) {
  #header {
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: transparent;
    height: 100px;

    &.scrolled {
      background-image: linear-gradient(white, rgba(255, 255, 255, 0) 165%);
    }
  }
}