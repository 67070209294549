@use "../../theme.scss" as theme;

#not-found-page {
  box-sizing: border-box;
  min-width: 0;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  h1 {
    margin: 0;
    font-family: 'Montserrat',system-ui,sans-serif;
    font-weight: 500;
    line-height: 1.33;
    color: #366B92;
    font-size: 25px;
    line-height: 1;
    font-weight: 700;
    font-size: 100px;
  }

  h2 {
    margin: 0;
    font-family: 'Montserrat',system-ui,sans-serif;
    font-weight: 500;
    line-height: 1.33;
    color: #366B92;
    font-size: 22px;
    padding-bottom: 25px;
    font-size: 25px;
  }

  a {
    text-decoration: none;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: theme.$otip-primary-color;
    color: #000;
    font-size: 16px;
    cursor: pointer;
    font-family: 'Open Sans',system-ui,sans-serif;
    border-width: 2px;
    border-radius: 4px;
    text-align: center;
    border-style: solid;
    border-color: theme.$otip-primary-color;
    font-weight: 600;
    &:hover {
      color: #FFFFFF;
      background-color: #E1CAAC;
      text-decoration: none;
      border-color: #E1CAAC;
    }
  }

  @media (max-width: theme.$xs) {
    h1 {
      font-size: 100px;
    }

    h2 {
      padding-bottom: 25px;
      font-size: 25px;
    }
  }

  @media (min-width: theme.$xs) {
    h1 {
      font-size: 100px;
    }

    h2 {
      padding-bottom: 25px;
      font-size: 25px;
    }
  }

  @media (min-width: theme.$s) {
    h1 {
      font-size: 100px;
    }

    h2 {
      padding-bottom: 25px;
      font-size: 25px;
    }
  }

  @media (min-width: theme.$m) {
    h1 {
      font-size: 200px;
    }

    h2 {
      padding-bottom: 30px;
      font-size: 40px;
    }
  }

  @media (min-width: theme.$l) {
    h1 {
      font-size: 200px;
    }

    h2 {
      padding-bottom: 30px;
      font-size: 40px;
    }
  }

  @media (min-width: theme.$xl) {
  }
}

