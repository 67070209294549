@use "../../theme.scss" as theme;

#footer {
  box-sizing: border-box;
  min-width: 0;
  display: flex;
  flex-direction: column;

  #footer-content-wrapper {
    box-sizing: border-box;
    min-width: 0;
    background-color: #FFFFFF;

    #footer-content {
      box-sizing: border-box;
      min-width: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      #logo-wrapper {
        img {
          height: auto;
          vertical-align: middle;
          border-style: none;
        }

        &.facet {
          img {
            max-width: 100%;
          }
        }

        &.otip {
          img {
            max-width: 150%;
          }
        }
      }


      #privacy-link {
        color: black;
        text-decoration: none;
        &:hover {
          text-decoration: none;
        }
      }
    }

    #download-btn-wrapper {
      &.hidden {
        opacity: 0;
        visibility: hidden;
      }

      #download-btn {
        text-decoration: none;
        padding: 10px;
        font-weight: 600;
        width: 231px;
        font-size: 16px;
        cursor: pointer;
        font-family: "Open Sans", system-ui, sans-serif;
        border-width: 2px;
        border-radius: 24px;
        text-align: center;
        border-style: solid;
        box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 4px 0px;

        &.facet {
          color: white;
          background-color: theme.$facet-primary-color;
          border-color: theme.$facet-primary-color;
        }

        &.otip {
          color: black;
          background-color: white;
          border-color: theme.$otip-primary-color;
        }
      }
    }
  }
}

@media (max-width: theme.$xs) {
  #footer-content-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 40px;
    padding: 10px 15px;

    #logo-wrapper {
      max-width: 90px;
    }

    #download-btn-wrapper {
      box-sizing: border-box;
      min-width: 0px;
      display: flex;
      z-index: 5;
      position: relative;
      text-align: right;
      opacity: 1;
      transition: all 0.5s ease 0s;
      justify-content: flex-end;

      &.margin {
        margin-right: 0;
      }
    }
  }
}

@media (min-width: theme.$xs) {
  #footer-content-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 40px;
    padding: 10px 15px;

    #footer-content {
      padding: 10px 0;
      max-height: 80px;

      #logo-wrapper {
        max-width: 90px;
      }
    }

    #download-btn-wrapper {
      box-sizing: border-box;
      min-width: 0px;
      display: flex;
      margin-right: 0px;
      text-align: center;
      justify-content: flex-end;

      &.margin {
        margin-right: 0;
      }
    }
  }
}

@media (min-width: theme.$s) {
  #footer-content-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 40px;
    padding: 10px 15px;

    #footer-content {
      padding: 10px 0;
      max-height: 80px;

      #logo-wrapper {
        max-width: 90px;
      }
    }

    #download-btn-wrapper {
      box-sizing: border-box;
      min-width: 0px;
      display: flex;
      margin-right: 0px;
      text-align: center;
      justify-content: flex-end;

      &.margin {
        margin-right: 0;
      }
    }
  }
}

@media (min-width: theme.$m) {
  #footer-content-wrapper {
    min-height: 100px;
    padding: 20px 40px;

    #footer-content {
      padding: 10px 0;
      max-height: 100px;

      #logo-wrapper {
        max-width: 132px;
      }
    }

    #download-btn-wrapper {
      right: 150px !important;
      bottom: 30px !important;
      position: fixed  !important;
      box-sizing: border-box;
      min-width: 0px;
      display: flex;
      z-index: 5;
      position: relative;
      text-align: right;
      opacity: 1;
      transition: all 0.5s ease 0s;
      justify-content: flex-end;

      &.margin {
        margin-right: 100px;
      }
    }
  }
}

@media (min-width: theme.$l) {
  #footer-content-wrapper {
    min-height: 100px;
    padding: 20px 40px;

    #footer-content {
      padding: 10px 0;
      max-height: 100px;

      #logo-wrapper {
        max-width: 132px;
      }
    }

    #download-btn-wrapper {
      right: 150px !important;
      bottom: 30px !important;
      position: fixed  !important;
      box-sizing: border-box;
      min-width: 0px;
      display: flex;
      z-index: 5;
      position: relative;
      text-align: right;
      opacity: 1;
      transition: all 0.5s ease 0s;
      justify-content: flex-end;

      &.margin {
        margin-right: 100px;
      }
    }
  }
}

@media (min-width: theme.$xl) {
  #footer-content-wrapper {
    min-height: 150px;
    padding: 25px 40px;

    #footer-content {
      padding: 20px 0;

      #logo-wrapper {
        max-width: 132px;
      }
    }

    #download-btn-wrapper {
      right: 150px;
      bottom: 60px;
      position: fixed;
      box-sizing: border-box;
      min-width: 0px;
      display: flex;
      z-index: 5;
      position: relative;
      text-align: right;
      opacity: 1;
      transition: all 0.5s ease 0s;
      justify-content: flex-end;

      &.margin {
        margin-right: 100px;
      }
    }
  }
}